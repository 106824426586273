
import {
  defineComponent,
  reactive,
  ref,
  getCurrentInstance,
  onMounted,
  toRefs,
} from "vue";
import { useRouter } from "vue-router"; //引入路由
import { mainServer } from "@/apiServer/installServer";
export default defineComponent({
  name: "Main",
  components: {},
  setup() {
    let data = reactive({
      Loginform: {
        system_user_info_account: "",
        system_user_info_password: "",
      },
      title: "",
      LoginLoading: false,
    });
    const UserInfo: any = ref({});
    const { proxy }: any = getCurrentInstance();
    const router = useRouter();
    const FnOnSubmit = function () {
      data.LoginLoading = true;
      console.log("FnOnSubmit:登录接口");
      const params = data.Loginform;
      console.log("FnOnSubmit:params ====>", params);
      mainServer.LoginApi(params, false).then((res: any) => {
        data.LoginLoading = false;
        console.log("FnOnSubmit: res ---->", res);
        if (res.data.stateMsg === "success") {
          proxy.$m.vuex("vuexUserInfo", res.data.data.res);
          sessionStorage.setItem("UserInfo", JSON.stringify(res.data.data.res));
          router.push({ path: "/main", query: {} });
        } else {
          console.log("proxy", proxy);
          proxy.$CommonFun.FnCommonMsg(res.data.data.res, "error");
        }
      });
    };
    const FnGetSettingApi = function () {
      const params = {};
      mainServer.SetConfigApi(params, true).then((res: any) => {
        console.log("FnGetSettingApi: ConfigInfo ====>", res.data.data.res);
        data.title = res.data.data.res[0].system_config_content;
        proxy.$m.vuex("vuexConfigInfo", res.data.data.res);
      });
    };
    onMounted(() => {
      FnGetSettingApi();
    });
    return {
      ...toRefs(data),
      FnOnSubmit,
      FnGetSettingApi,
    };
  },
});
